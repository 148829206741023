@import "../../css/variables.module";

.clock_box {
    color: #CDFD34;
}

.clock__alert--modal {
    width: 25vw;
    padding: 20px;
    border-radius: 0.7em;
    text-align: center;
    position: fixed;
    top: 25%;
    left: 35%;
    z-index: 40;
    background-color: $main-color;


    // background-color: red;
}