@import "./reset";
@import "./generalUses";
@import "./mixins";
@import "./animations";


//variables
$main-color: #4E4C67;
$secondary-color: #2B2A38;
$secondary-accent-color: #252533;
$third-color: #3f3d56;
$action-color: #CDFD34;


//variables - please dont change their names :clownface: :Lbozo: xC
$first: #CDA6AD;
$second: #72AABB;
$third: #000000;
$fourth: #3F3D56;


// general use classes that require vaiables and mixins to work (has to be put in this file,
// NOT in the generalUses.scss nor in mixins.scss)

.action_color {
    color: $action-color;
}


.primary_color {
    color: $first;
}

.secondary_color {
    color: $second;
}

.third_color {
    color: $third;
}

.fourth_color {
    color: $fourth;
}

.main_color {
    color: $main-color;
}

.action {
    @include button($third, $action-color, $action-color);
    border-radius: 1em;
    @include flex(center, center, row);
}

.actionI {
    @include actionBtnI($third);
}

.action--second {
    @include button(white, $second, $second);
    border-radius: 1em;
}

.action__red {
    @include flex(center, center, row);
    cursor: pointer;
    background-color: red;
    color: black;
    font-weight: bold;
    border-radius: 1em;
    padding: 5px 15px;
    transition: all 0.1s;

    &:hover,
    &:focus {
        box-shadow: 0 0 10px -3px;
        color: white;
        background-color: red;
        outline: none;
    }
}

.action__neutral {
    @include flex(center, center, row);
    cursor: pointer;
    background-color: transparent;
    color: white;
    border-radius: 1em;
    padding: 5px 15px;
    font-weight: bolder;
    box-shadow: 0 0 3px -1px;
    transition: all 0.1s;
    border: 2px solid transparent;

    &:hover, &:focus {
        border: 2px solid white;
        background-color: transparent;
        box-shadow: none;
        outline: none;
    }
}

.action__border {
    cursor: pointer;
    background-color: transparent;
    color: $action-color;
    border: 2px solid $action-color;
    padding: 5px 15px;
    border-radius: 1em;
    font-weight: bolder;
    transition: all 0.3s;
}

.input,
.action__input {
    border: 1px solid transparent;
    border-radius: 0.3em;
    padding: 15px;
    color: $third;
    transition: all 0.1s;
    height: 45px;
    font-weight: 600;

    &::placeholder {
        color: $third;
        font-weight: 600;
    }
}

.action__input {
    &:focus {
        border: 1px solid $action-color;
        color: $action-color;
        background-color: transparent;
        outline: none;

        &::placeholder {
            color: $action-color;
        }
    }
}

.input {
    &:focus {
        box-shadow: 0 0 7px white;
    }
}




//autofill inputs
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid $action-color;
    -webkit-text-fill-color: $action-color;
    -webkit-box-shadow: 0 0 0px 1000px rgb(26, 41, 10) inset;
    transition: background-color 1000s ease-in-out 0s;
}