@import "../../css/variables.module";

.modal {
    max-height: 150px;
    max-width: 50vw;
    left: 25%;
    position: absolute;
    top: 25%;
    z-index: 4;
    background-color: $main-color;
    border-radius: 1.3em;
    box-shadow: 0 0 3px;

    input {
        margin: 0;
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99998;
}

.modal__close {
    position: absolute;
    right: 10px;
    top: 10%;
}