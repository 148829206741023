@import "../../../../css/variables.module";

.builder__replace-element-modal__form {
    position: absolute;
    background-color: $main-color;
    height: 300px !important;   
    padding: 15px 25px;
    top: 40vh;
    left: 40%;
    z-index: 999;
    border-radius: 0.3em;
    box-shadow: 0 0 9px -3px black;
    width: 500px !important;
}

// .replaceable-type-box {
//     border: 1px solid $action-color;
//     border-radius: 5px;
//     color: $action-color;
// }

.box--active {
    outline: 2px solid $action-color ;
    color: red;
    svg{
        path{
            fill: $action-color !important;
        }
    }
}
.select__element{
    cursor: pointer;
    border-radius: 0.3em;
    transition: all 0.1s;
    
    &:hover{
        box-shadow: 0 0 13px -2px rgb(0, 0, 0);
    }
    
}

.icon-modal {
    @include flex(center,center,column);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $main-color;
    border-radius: 0.3em;
    box-shadow: 0 0 12px -3px;
    padding: 20px;
    z-index: 1000;

    .icon-container {
        .select__element {
            width: 60px;
            height: 60px;
            svg {
                padding: 10px;
                width: 100%;
                height: 100%;
                path{
                    fill:white;
                }
            }
        }
    }
}