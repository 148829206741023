@import url("https://fonts.cdnfonts.com/css/helvetica-neue-55");
@import "./css/variables.module";

* {
    padding: 0;
    margin: 0;
    -ms-overflow-style: none; 
    scrollbar-width: none;
    font-family: Arial;
}

.h1,.h2,.h3,.h4,.h5,.h6, h1, h2, h3, h4, h5, h6 {
    font-family: Arial!important;
}

*::-webkit-scrollbar {
    display: none;
}

body {
    background-color: $main-color;
    overscroll-behavior: none;
}

@media (prefers-reduced-motion: no-preference) {
    html {
        scroll-behavior: smooth;
    }
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.grid-x {
    width: 100%;
    margin: 0 auto;
}

.grid-y {
    height: 100%;
    margin: 0 auto;
}

.button {
    width: 100%;
}

#modal--container {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgb(39, 39, 39, 0.3);
    top: 0;
    left: 0;
    overflow: hidden;
}

.modal {
    background-color: #f2f2f2;
    height: 650px;
    display: block;
}

#close--btn {
    color: rgb(152, 152, 152);
    padding: 12px 20px;
    font-size: 32px;
}

.control--btn {
    margin-top: 50px;
    font-size: 19px;
    border-radius: 15px;    
}

