//general uses
.bold {
	font-weight: bold;
}

.bolder {
	font-weight: bolder;
}

.underline {
	text-decoration: underline;
}

.uppercase {
	text-transform: uppercase;
}

.lowercase {
	text-transform: lowercase;
}

.red {
	color: red;
}

.white {
	color: white;
}

.black {
	color: black;
}

.green_back {
	background-color: green !important;
}

.red_back {
	background-color: red !important;
}

.yellow_back {
	background-color: yellow !important;
}

.orange_back {
	background-color: orange !important;
}

.purple_back {
	background-color: purple !important;
}

.pink_back {
	background-color: pink !important;
}

.text-faint {
	opacity: 0.7;
}



.pointer {
	cursor: pointer;
}

.button.disabled,
.button.disabled:focus,
.button.disabled:hover,
.button[disabled],
.button[disabled]:focus,
.button[disabled]:hover {
	background-color: transparent;
}

