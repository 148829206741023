@import "../../../../css/variables.module";

.builderTutorial {
    position: absolute;
    width: 100px;
    max-width: 200px;
    height: fit-content;
    border-radius: 0.3em;
    z-index: 999;
    background-color: $main-color;
    box-shadow: 0 0 7px -2px black;
}

.tippy-container{
    @include flex(center,center,row);
    flex-direction: row !important;
}

.tippy-box[data-theme~='tnd'] {
    background-color: $second;
    color: $action-color;
}

.tippy-box[data-theme~='tnd']>.tippy-arrow {
    color: $second;
}
.tippy-content{
    box-shadow: 0 0 7px -3px black;
    div{
        font-weight: bold;
        display: flex;
        flex-direction: column ; 
        padding: 7px 15px;
        button{
            margin: 5px;
        }
    }
}