@import "../../css/variables.module";

.icons {
    cursor: pointer;
    font-size: 19px;
    margin-left: 4px;
    margin-right: 4px;
}
.icon:hover .icon-info {
    display: block;
    opacity: 1;
    transform: translateX(-10%);
    transform: translateY(20%);
    z-index: 100;
    white-space: nowrap;
    transition: opacity 0.3s ease, visibility 0s 0.7s; // Add a delay of 1 second to visibility
    visibility: visible;
}

.icon-info {
    position: absolute;
    background-color: $third;
    color: white;
    margin-top: 35px;
    // margin-left: 20px;
    padding: 0 10px;
    border-radius: 5px;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease; // Transition for the opacity
    text-align: center;
    // display: none;
}
