//mixins
@mixin flex ($justify-content, $align-items, $flex-direction) {
    display: flex;
    justify-content: $justify-content;
    align-items: $align-items;
    flex-direction: $flex-direction;
}

// color , background-color, border, border-radius, transition, font-weight
@mixin button($text_color, $background_color, $border_color) {
    cursor: pointer;
    border: 2px solid $border_color;
    background-color: $background_color;
    padding: 5px 15px;
    font-weight: bold;
    color: $text_color;
    transition: all 0.1s ease-in-out;

    &:hover,
    &:focus {
        background-color: transparent;
        color: $background_color;
        outline: none;
    }
}

@mixin actionBtnI($border_color) {
    cursor: pointer;
    background-color: transparent;
    color: $action-color;
    padding: 5px 10px 5px 10px;
    font-weight: bold;
    border-radius: .7em;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    border: 2px solid transparent;
    transition: all .1s;
    

    &:hover {
        border-radius: 1.7em;
        background-color: $action-color;
        color: $third;
        
    }
}

@mixin actionBtn {
    background-color: $action-color;
    color: black;
    padding: 5px 10px 5px 10px;
    font-weight: bolder;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    border: 2px solid transparent;
    transition: all 0.1s;
    border-radius: .7em;

    &:hover {
        border-radius: 1.7em;
        background-color: transparent !important;
        color: $action-color;
        transition: all 0.1s;
        border: solid 2px $action-color;
        cursor: pointer;
    }
}

@mixin actionTxt {
    color: $action-color;
}

@mixin coolLinkHover ($height, $width, $bottom , $background_color) {

    a,
    a:visited,
    a:hover,
    a:active {
        backface-visibility: hidden;
        position: relative;
        transition: 0.3s color ease;
        text-decoration: none;
        color: #81b3d2;
    }

    a.before:before,
    a.after:after {
        content: "";
        transition: 0.3s all ease;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        position: absolute;
    }

    a.after:after {
        bottom: $bottom;
    }

    a.before:before,
    a.after:after {
        height: $height;
        width: 0;
        background: $background_color;
    }

    a.third:after {
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    a.after:hover:after {
        width: $width;
        border-radius: 1em;
    }
}

@mixin coolLinkFocus ($height, $width, $bottom , $background_color) {

    a,
    a:visited,
    a:focus,
    a:active {
        backface-visibility: hidden;
        position: relative;
        transition: 0.3s color ease;
        text-decoration: none;
        color: #81b3d2;
    }

    a.before:before,
    a.after:after {
        content: "";
        transition: 0.3s all ease;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        position: absolute;
    }

    a.after:after {
        bottom: $bottom;
    }

    a.before:before,
    a.after:after {
        height: $height;
        width: 0;
        background: $background_color;
    }

    a.third:after {
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    a.after:focus:after {
        width: $width;
        border-radius: 1em;
    }
}

@mixin coolLinkSquareSim($color) {

    a,
    a:visited,
    a:hover,
    a:active {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        position: relative;
        transition: 0.3s color ease;
        text-decoration: none;
        font-size: 4.5em;
    }

    a:hover {
        color: $color
    }

    a.before:before,
    a.after:after {
        content: "";
        transition: 0.3s all ease;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        position: absolute;
    }

    a.before:before {
        top: -0.25em;
    }

    a.after:after {
        bottom: -0.25em;
    }

    a.before:before,
    a.after:after {
        height: .55rem;
        width: 0;
        background: $color;
    }

    a.before:hover:before,
    a.after:hover:after {
        width: 100%;
    }

    .square {
        box-sizing: border-box;
        margin-left: -0.4em;
        position: relative;
        overflow: hidden;
        border-radius: 0.3em;
    }

    .square {
        a {
            position: static;
            font-size: 100%;
        }
    }

    .square:before,
    .square:after {
        content: "";
        box-sizing: border-box;
        transition: 0.3s all ease;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        position: absolute;
        width: 5px;
        width: 0.35rem;
        height: 0;
        background: $color
    }

    .square:before {
        left: 0;
        bottom: -0.2em;
    }

    .square:after {
        right: 0;
        top: -0.2em;
    }

    .square {
        a:before {
            left: 0;
            transition: 0.3s all ease;
        }
    }

    .square {
        a:after {
            right: 0;
            transition: 0.3s all ease;
        }
    }

    .square:hover:before,
    .square:hover:after {
        height: calc(100% + 0.4em);
    }

    .square:hover a:before,
    .square:hover a:after {
        width: 100%;
    }
}

@mixin coolLinkSquare($color) {

    a,
    a:visited,
    a:hover,
    a:active {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        position: relative;
        transition: 0.3s color ease;
        text-decoration: none;
        font-size: 4.5em;
    }

    a:hover {
        color: $color
    }

    a.before:before,
    a.after:after {
        content: "";
        transition: 0.3s all ease;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        position: absolute;
    }

    a.before:before {
        top: -0.25em;
    }

    a.after:after {
        bottom: -0.25em;
    }

    a.before:before,
    a.after:after {
        height: .55rem;
        width: 0;
        background: $color;
    }

    a.before:hover:before,
    a.after:hover:after {
        width: 100%;
    }

    .square {
        box-sizing: border-box;
        margin-left: -0.4em;
        position: relative;
        overflow: hidden;
        border-radius: 0.3em;
    }

    .square {
        a {
            position: static;
            font-size: 100%;
        }
    }

    .square:before,
    .square:after {
        content: "";
        box-sizing: border-box;
        transition: 0.3s all ease;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        position: absolute;
        width: 5px;
        width: 0.35rem;
        height: 0;
        background: $color
    }

    .square:before {
        left: 0;
        bottom: -0.2em;
    }

    .square:after {
        right: 0;
        top: -0.2em;
    }

    .square {
        a:before {
            left: 0;
            transition: 0.3s all ease;
        }
    }

    .square {
        a:after {
            right: 0;
            transition: 0.3s all ease;
        }
    }

    .square:hover:before,
    .square:hover:after {
        height: calc(100% + 0.4em);
    }

    .square:hover a:before,
    .square:hover a:after {
        width: 100%;
    }

    .square.individual:before {
        transition-delay: 0.6s;
    }

    .square.individual:after {
        transition-delay: 0.2s;
    }

    .square.individual {
        a:after {
            transition: 0.25s all ease 0.4s;
        }
    }
}

.flex {
    @include flex(center, center, row);
}