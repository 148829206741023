@import "../variables.module.scss";

.modal__question {
    color: white;
}

.modal__confirm {
    background-color: #6E6C88;
    margin-top: 10px;
    border-radius: .7em;
    font-weight: 600;
    width: 300px;
    max-height: 100px;
    height: 100%;
    position: absolute;
    right: 30px;
    z-index: 100;
    padding: 5px 15px;
}

.modal__confirm div {
    @include flex(center, center, row);
}

.modal__confirm--confirm {
    background-color: $action-color;
    border: 2px solid $action-color;
    transition: all 0.1s;

    &:hover {
        background-color: transparent;
        border-radius: 1em;
        color: $action-color;
    }
}

.modal__confirm--cancel {
    border: 2px solid red;
    background-color: red;
    color: $third;
    transition: all 0.1s;

    &:hover {
        background-color: transparent;
        border-radius: 1em;
        color: red;
    }
}

.modal__confirm--confirm,
.modal__confirm--cancel {
    cursor: pointer;
    margin: 5px;
    font-weight: bold;
    border-radius: .7em;
    padding: 5px;
}