@import "../../css/variables.module";

.newtons-cradle {
    @include flex(center, center, row);
    --uib-size: 90px;
    --uib-speed: 1.2s;
    --uib-color: $action-color;
    position: fixed;
    top: 40%;
    width: var(--uib-size);
    height: var(--uib-size);
}

.newtons-cradle__dot {
    @include flex(center, center, row);
    position: relative;
    height: 100%;
    width: 25%;
    transform-origin: center top;
}

.newtons-cradle__dot::after {
    content: '';
    display: block;
    width: 100%;
    height: 25%;
    border-radius: 50%;
    background-color: var(--uib-color);
}

.newtons-cradle__dot:first-child {
    animation: swing var(--uib-speed) linear infinite;
}

.newtons-cradle__dot:last-child {
    animation: swing2 var(--uib-speed) linear infinite;
}

.loader-background {
    z-index: 0;
    width: 10vw;
    height: 10vh;
}