@import "../../css/variables.module";

.action__arrow--button {
    @include flex(center, center, row);
    @include button($third, transparent, $action-color);
    position: absolute;
    width: 48px;
    height: 48px;
    border: 2px solid $action-color;
    border-radius: 5px;
    transition: all .2s;
    font-weight: normal;
    
    &:hover {
        border: 2px solid white;
        border-radius: 1.7em;

        .action__arrow {
            color: white;
        }

        #action__arrow {
            color: white;
        }
    }
    .icon{
        @include flex(center, center, row);
    }
}

#action__arrow,
.action__arrow {
    color: $action-color;
}