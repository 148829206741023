@import "../../../../css/variables.module";


.builder__pages-container {
    height: 45px;
}

.builder__top-section {
    min-height: 65px;

    #builder__panels {
        min-height: 65px;
    }
}



.name-new-template {
    margin: 0 auto;
    color: $third;
    transition: all 0.1s;
    border-radius: .4em;

    &:hover,
    &:focus {
        background-color: transparent;
        color: $action-color;
        box-shadow: 0px 0px 2px 2px $action-color;
        outline: none;

        &::placeholder {
            color: $action-color;
        }
    }

    &::placeholder {
        color: $third;
    }
}

.select__make-template {
    cursor: pointer;
    border-radius: .7em;
    margin: 0 auto;
    color: $third;
    border: 2px solid $action-color;
    transition: all 0.1s;

    &:hover {
        background-color: transparent;
        color: white;
        box-shadow: 0px 0px 2px 2px white;
    }
}

.relog__modal {
    position: fixed;
    z-index: 4;
    border-radius: 0 0 .7em 0;
    box-shadow: 0 0 6px 3px rgb(92, 92, 92);
    background-color: $fourth;
    top: 0;
    left: 0%;
}

.project__name {
    color: #CDFD34;
    margin: 0;
    padding-left: 5px;
}

.project__mode {
    color: #72AABB;
    margin: 0;
    padding-left: 5px;
}

.page__name__change,
.page__remove {
    visibility: hidden;
}

.page__remove {
    &:hover {
        color: red !important;
    }
}

.buider__left__No-Dropdown {
    border-left: 1px solid $second;
}

// Dropdowns for the top nav bar of the builder
// general things

@media screen and (max-width: 990px) {

    .buider__left__No-Dropdown,
    .builder__center__No-Dropdown,
    .builder__right__No-Dropdown {
        display: none;
    }

    .builder--left__dropdown--toggle,
    .builder--right__dropdown--toggle,
    .builder--center__dropdown--toggle,
    #js-builder__panels--utility {
        display: flex;
    }
}

@media screen and (min-width: 991px) {

    .buider__left__No-Dropdown,
    .builder__center__No-Dropdown,
    .builder__right__No-Dropdown {
        display: flex;
    }

    .builder--left__dropdown--toggle,
    .builder--center__dropdown--toggle,
    .builder--right__dropdown--toggle,
    .builder--left__dropdown,
    .builder--center__dropdown,
    .builder--right__dropdown,
    .builder--center__dropdown {
        display: none !important;
    }
}

.builder__dropdown--arrow-right {
    display: inline-block;
    font-size: 30px;
    margin-left: 5px;
    margin-bottom: 1px;
    transition: transform 0.2s ease;
    transform-origin: center;
}

.builder--left__dropdown:hover .builder__dropdown--arrow-right,
.builder--center__dropdown:hover .builder__dropdown--arrow-right,
.builder--right__dropdown:hover .builder__dropdown--arrow-right,
.builder__pages--dropdown:hover .builder__dropdown--arrow-right {
    transform: rotate(90deg);
}

@media screen and (max-width: 990px) {

    .builder--left__dropdown--toggle,
    .builder--center__dropdown--toggle,
    .builder--right__dropdown--toggle {
        @include flex(center, center, row);
        @include button($third, transparent, transparent);
        border-radius: 0.3em;
        color: $action-color ;

        &:hover,
        &:focus {
            // background-color: $action-color;
            color: $third;
        }

        padding: 3px 10px;
    }

    .builder--right__dropdown,
    .builder--center__dropdown,
    .builder--left__dropdown {
        // Apply cool link effect to the menu when the dropdown is hovered
        @include coolLinkSquareSim($action-color);

        // Additional styling for the menu when the dropdown is hovered
        // Add any other styles you want to apply to the menu here
    }

    // .builder--right__dropdown--menu {

    //     .builder--right__dropdown .square{
    //         a:before,
    //         a:after {
    //             width: 100%;
    //         }
    //     }
    // }

}

.builder--left__dropdown--menu,
.builder--center__dropdown--menu,
.builder--right__dropdown--menu,
.builder__pages--dropdown--menu {
    display: none;
    position: absolute;
    z-index: 2;
    padding: 10px;
    background-color: $second;
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.4);
    border-radius: 0.3em;
    top: calc(100%); // Adjust the distance between the toggle and the menu here
}

// dropdown on the TOP LEFT side of the builder

.builder--left__dropdown {
    position: relative;
    @include flex(center, center, row);
}

.builder--left__dropdown:hover {
    .builder--left__dropdown--toggle {
        border-radius: 0.3em 0.3em 0 0;
    }
}

.builder--left__dropdown .gjs-pn-buttons {
    @include flex(center, center, row);
    margin: 0 auto;
    text-align: center;
    background: transparent;
}

.builder--left__dropdown--menu {
    .gjs-one-bg {
        background-color: $second;
    }
}


.builder--left__dropdown:hover .builder--left__dropdown--menu {
    @include flex(center, center, row);
}

.builder__pages--dropdown:hover .builder__pages--dropdown--menu {
    @include flex(center, center, column);
}


.builder--left__dropdown-items {
    @include flex(center, center, row);
    padding: 10px;
    color: white;
}


.builder--left__dropdown-items>* {
    margin-bottom: 8px;
}


@media screen and (min-width: 1024px) {
    .builder--left__dropdown--menu {
        .gjs-pn-panel {
            .gjs-pn-buttons {
                @include flex(flex-start, flex-start, column);
            }
        }
    }

}

// dropdown on the TOP CENTER side of the builder

.builder--center__dropdown:hover {
    .builder--center__dropdown--toggle {
        border-radius: 0.3em 0.3em 0 0;
    }
}

.builder--center__dropdown {
    position: relative;
}

.builder--center__dropdown:hover .builder--center__dropdown--menu {
    @include flex(center, center, row);
}


.builder--center__dropdown--menu button {

    padding: 5px;
    text-decoration: none;
    display: block;
    background-color: transparent;
    border: none;
    width: 100%;
    text-align: center;
    border-radius: 0;
    transition: all 0.1s;
}


.builder--center__dropdown--menu button:hover {
    background-color: rgba(142, 212, 233, 0.745);
    border-radius: 0.3em;
    box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.2);
}

// dropdown on the TOP RIGHT side of the builder 

.builder--right__dropdown {
    @include flex(center, center, row);
    position: relative;
}

.builder--right__dropdown--menu {
    width: max-content;
    margin-left: -100%;

    .gjs-pn-buttons {
        padding: 0;
    }
}

.builder--right__dropdown:hover .builder--right__dropdown--menu {
    @include flex(center, center, row);
}

.builder--right__dropdown:hover {
    .builder--right__dropdown--toggle {
        border-radius: 0.3em 0.3em 0 0;
    }
}

.builder--right__dropdown--menu div {
    @include flex(center, center, row);
    background-color: transparent;
}

// PAGES dropdown


.builder__pages--dropdown--toggle {
    cursor: pointer;
    color: $action-color;
    position: relative;
    background: #2B2A38;
    border: none;
    padding: 10px;
    border-radius: 1em;
    z-index: 5;
    transition: all 0.1s;
    @include coolLinkSquareSim($action-color);

    &:hover {

        color: $action-color;

        .builder__pages--dropdown--menu {
            display: block !important;
        }
    }
}

@media screen and (max-height: 895px) {
    .builder__pages--dropdown--menu {
        top: 53px !important;
    }
}

.builder__pages--dropdown--menu {
    @include coolLinkHover(0.2rem, 85%, 0.3em, $second);
    right: 10%;
    background-color: white;
    border: 1px solid $second;
    width: 200px !important;
    padding: 10px;
    box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.4);
    border-radius: 1em;
    top: 60px; // Adjust the distance between the toggle and the menu here

    .builder__pages--dropdown--item {
        padding: 8px;
        font-size: 17px;
        margin-top: 5px;
        background-color: white;
        border-radius: 0.5em;
        cursor: pointer;
        transition: all 0.1s;

        &:hover {
            box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.4);

            .page__name__change,
            .page__remove {
                visibility: visible;
            }
        }
    }

    .builder__pages--dropdown--item.selected {
        background-color: $second;
        color: white;
        transition: all 0.1s;

        #dropdown__pages__input {
            color: white;
        }

        &::after,
        &::before {
            background: white !important;
        }
    }
}



.builder__pages--dropdown--add-btn {
    @include flex(center, center, row);
    cursor: pointer;
    text-align: center;
    border-radius: 1em;
    padding: 7px;
    margin-top: 8px;
    background-color: $action-color;
    color: $third;
    font-weight: bolder;
    border: 2px solid transparent;
    transition: all 0.1s;

    &:hover {
        box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.4);
    }
}

.builder__pages--dropdown--toggle {
    svg {
        color: $action-color;
        font-size: 20px;
        transition: transform 0.1s;
    }
}

.builder__pages--dropdown--toggle:hover {
    svg {
        color: $action-color;
        transform: rotate(90deg);
    }
}

.js--page-name-input {
    border: 0;
    padding: 7px 3px;
    text-align: center;

    &:disabled {
        background-color: transparent;
    }
}

.builder--delete-page-modal {
    height: 20vh;
}

.confirm__delete__page {
    cursor: pointer;
    background-color: red;
    padding: 5px 15px;
    color: $third;
    border-radius: 1em;
    font-weight: bold;
    transition: all 0.1s;

    &:hover {
        color: white;
        box-shadow: 0 0 5px;
        outline: 1px solid red;
    }
}

.transparent__background {
    background: transparent;
    outline: none;
}

// END OF DROPDOWNS

.text__pop-up {
    position: relative;
    color: white;
    background-color: $second;
    padding: 5px;
    border-radius: 0.3em;
    transition: all 0.1s;
    box-shadow: 0px 0px 5px 1px;
    z-index: 30;
}

.project__credentials {
    max-width: 130px; //sets a max width for the cell, containing the name and mode of the prject
    //in order for when the name pops up, it doesnt widen the cell.
    // Adjust if necessary 
}

.activeDevice {
    color: $action-color !important;
    .icon{
        color: $action-color !important;
    }
}

.builder--center__dropdown:hover {
    .device__icon {
        transition: all 0.2s;
        border-radius: 0.6em !important;
    }

    .activeDevice {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
        background-color: $action-color ;
        border-radius: 0.6em;

        .icons {
            color: $third
        }
    }
}


.device__icon {
    border-radius: 0.6em;
    transition: all 0.2s;

    .icon {
        @include flex(center, center, row);
    }

    .icons {
        border: 1px solid transparent;
        border-radius: 0.6em;
        transition: all 0.2s;
    }

    &:focus {
        .icons {
            // border: 1px solid $action-color;
            color: $action-color;
        }
    }
}

.rename-modal--content {
    height: 100%;
    width: 100%;
}

.rename-modal {
    cursor: auto;
    position: fixed;
    height: 30%;
    width: 50% !important;
    top: 35%;
    left: 25%;
    border-radius: 0.3em;
    box-shadow: 0 0 20px -4px black;
    z-index: 9;
    background-color: $main-color;
}


.section__new--name__modal {
    border-radius: 0.3em;
    transition: all 0.1s;

    &::placeholder {
        color: $third;
    }

    &:focus {
        background-color: transparent;
        outline: 2px solid $action-color;
        color: $action-color;
        box-shadow: 0 0 7px -1px;

        &::placeholder {
            color: $action-color;
        }
    }
}

.section__rename__button,
.section__replace__button {
    cursor: pointer;
    width: 25%;
    margin-right: 5px;
    padding: 5px;
    border-radius: 0.3em;
    color: $second;
    transition: all 0.1s;

    &:hover {
        background-color: $action-color;
        color: $main-color;
    }
}

.builder__page-tabs {
    background-color: $third-color;
}

.builder__page-tab {
    cursor: pointer;
    background-color: $third-color;
    color: white;
    opacity: 0.4;
    transition: all 0.1s;

    &:hover {
        opacity: 0.8;
    }
}

.builder__page-tab>div {
    height: 100% !important;
}

.builder__page-tab--active {
    background-color: $main-color !important;
    border-bottom: 1px solid $action-color;
    color: $action-color;
    opacity: 1;

    &:hover {
        opacity: 1;
    }

    .builder__page-tab__delete {
        cursor: pointer;

    }
}

.builder__page-tab__delete {
    &:hover {
        color: red;
    }
}

.builder__pages__add-btn--container {
    height: 100%;
    @include flex(center, center, row);
}

.builder__pages__add-btn {
    cursor: pointer;
    width: 42.66px;
    height: 42.66px;
    @include flex(center, center, row);

    .builder__pages__add-btn--circle {
        @include flex(center, center, row);
        height: 50%;
        width: 50%;
        border-radius: 100%;
        font-weight: bold;
        background-color: $action-color;
        transition: all 0.2s;

        &:hover {
            // height: 80%;
            // width: 80%;
            // font-size: 25px;
            scale: 1.5;
            border-radius: 0.3em;
        }
    }
}

.builder__pages-container>div {
    height: 100%;
}

.tab__input {
    cursor: text;
    background-color: transparent !important;
    border: none;
    color: white;
    pointer-events: none;
}

.builder--delete-page-modal__tabs {
    position: absolute;
    font-size: small !important;
    top: 6%;
    left: 30%;
    z-index: 9999999;
    background-color: $main-color;
    color: white;
    width: 35vw !important;
    height: max-content;
    border-radius: 0.3em;
    padding: 10px;

}

.link-preview {

    a,
    a:visited,
    a:hover,
    a:active {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        position: relative;
        transition: 0.5s color ease;
        text-decoration: none;
        font-size: large;
        color: $action-color;
    }

    a:hover {
        color: $action-color;
    }

    a.before:before,
    a.after:after {
        content: "";
        transition: 0.2s all ease;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        position: absolute;
    }

    a.before:before {
        top: -0.25em;
    }

    a.after:after {
        bottom: -0.25em;
    }

    a.before:before,
    a.after:after {
        height: 3px;
        height: 0.15rem;
        width: 0;
        background: $action-color;
        border-radius: 0.3em;
    }

    a.first:after {
        left: 0;
    }

    a.second:after {
        right: 0;
    }

    a.third:after,
    a.sixth:before,
    a.sixth:after {
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    a.fourth:before,
    a.fourth:after {
        left: 0;
    }

    a.fifth:before,
    a.fifth:after {
        right: 0;
    }

    a.seventh:before {
        right: 0;
    }

    a.seventh:after {
        left: 0;
    }

    a.eigth:before {
        left: 0;
    }

    a.eigth:after {
        right: 0;
    }

    a.before:hover:before,
    a.after:hover:after {
        width: 100%;
    }

}

.change-url__modal {
    height: 200%;
    z-index: 99999;
    top: 0;
    background-color: $main-color;
    @include flex(center, center, row);
    border-radius: inherit;

    .icon {
        height: max-content;
    }

    input {
        margin: 0;
    }
}

.hover-scale{
    transition: all 0.15s;
    &:hover{
        scale: 1.2;
        color: red;
    }
}

.hover-rotate {
    transition: all 0.15s;
}

.hover-rotate--wrapper {
    &:hover {
        .hover-rotate {
            transform: rotate(90deg);
            transform-origin: center center;
            scale: 1.2;
        }
    }
}

.tnd__logo{
    min-width: 100px;
    max-width: 200px;
}