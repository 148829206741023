@import "../../../css/variables.module";

.image-modal{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.7);
}

.image-modal > .icon {
    position: absolute;
    top: 10vh;
    right: 10vw;
}

.image-modal__content{
    background-position: center;
    background-size: 80vh;
    background-repeat: no-repeat;
    width: 80vw;
    height: 80vh;
}


.module__login-button {
    position: absolute;
    text-align: center;
    z-index: 3;
    border-radius: 100%;
}

.module-form {
    position: absolute;
    left: 15%;
    top: 5%;
    height: 80vh;
    width: 70%;
    padding: 20px;
    z-index: 3;
    background-color: $secondary-color;
    color: white;
    box-shadow: 0 0 7px -3px;
    border-radius: 15px;
    transition: all 0.1s;
}

@media screen and (max-height : 600px) {
    .module-form {
        height: 100vh;
        top: 0;
    }
    .module-form--login{
        height: 100vh !important;
        top: 0;
    }
}

.module-form__inputs {
    border-radius: 10px;
    border: 0;
    // height: 34px;
    opacity: 1;
    padding: 10px;
    transition: all 0.1s;
    border: 2px solid transparent;

    &::placeholder {
        color: $third;
    }

    &:focus {
        border: 2px solid $action-color;
        background-color: transparent;
        color: $action-color;

        &::placeholder {
            color: $action-color;
        }
    }

    &.error {
        border-color: red;
        background-color: transparent !important;
        color: red;

        &::placeholder {
            color: red;
        }
    }

    transition: border-color 0.3s,
    color 0.3s;
}

.action--red {
    @include button($third, red, red);
    border-radius: 0.7em;
}

.module-form__input--error {
    border: 2px solid red;
    background-color: red !important;

    input::placeholder {
        color: red;
    }
}

.module-form__inputs.error::placeholder {
    color: red;
}