// If you want to write a new animation do it here

//bounce

.animation--bounce:hover {
    animation: bounce 1s ease;
}

@keyframes bounce {
    0% {
        transform: translateY(0%);
    }

    20% {
        transform: translateY(-15%);
    }

    40% {
        transform: translateY(0%);
    }

    55% {
        transform: translateY(-7%);
    }

    70% {
        transform: translateY(0%);
    }

    85% {
        transform: translateY(-3%);
    }

    100% {
        transform: translateY(0);
    }
}

// shake  ( for wrong inputs )

.shake {
    animation: shake 0.9s ease-in-out;
}

@keyframes shake {

    0%,
    100% {
        transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateX(-10px);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translateX(10px);
    }
}



//pulse ( for like button,etc. )

.pulse {
    transition: all 0.1s;
    animation: pulse 0.2s ease;
}


@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}


@keyframes pulse__text {

    0%,
    100% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.1);
        opacity: 0.9;
    }

    75% {
        transform: scale(1.2);
        opacity: 0.87;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

// scan

@keyframes scan {
    0% {
        background-position: 0 -100vh;
    }

    35%,
    100% {
        background-position: 0 100vh;
    }
}

// swing 1 and 2 (for loader)

@keyframes swing {
    0% {
        transform: rotate(0deg);
        animation-timing-function: ease-out;
    }

    25% {
        transform: rotate(70deg);
        animation-timing-function: ease-in;
    }

    50% {
        transform: rotate(0deg);
        animation-timing-function: linear;
    }
}

@keyframes swing2 {
    0% {
        transform: rotate(0deg);
        animation-timing-function: linear;
    }

    50% {
        transform: rotate(0deg);
        animation-timing-function: ease-out;
    }

    75% {
        transform: rotate(-70deg);
        animation-timing-function: ease-in;
    }
}



@keyframes jiggle {
    0% { transform: rotate(0); }
    25% { transform: rotate(15deg); }
    50% { transform: rotate(0); }
    75% { transform: rotate(-15deg); }
    100% { transform: rotate(0); }
}



@keyframes slideDown {
    from {
        max-height: 0;
    }
    to {
        max-height: 100px;
    }
}